exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-concorso-monza-2022-index-js": () => import("./../../../src/pages/concorso-monza-2022/index.js" /* webpackChunkName: "component---src-pages-concorso-monza-2022-index-js" */),
  "component---src-pages-en-faq-index-js": () => import("./../../../src/pages/en/faq/index.js" /* webpackChunkName: "component---src-pages-en-faq-index-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-faq-index-js": () => import("./../../../src/pages/it/faq/index.js" /* webpackChunkName: "component---src-pages-it-faq-index-js" */),
  "component---src-pages-it-js": () => import("./../../../src/pages/it.js" /* webpackChunkName: "component---src-pages-it-js" */)
}

